import React from "react";
import { Box, Typography } from "@mui/material";
import FetchData from "../Fetch";
import { useTheme } from "@emotion/react";

const BenifitCard = () => {
  const theme = useTheme();
  const apiUrl1 = process.env.REACT_APP_API_URL;
  const apiUrl =
    process.env.REACT_APP_API_URL + "/api/product-benefits?populate=*";
  const { data } = FetchData({ url: apiUrl });

  return (
    <Box
      sx={{
        display: "grid",
        gridGap: "2rem",
        gridTemplateColumns: "repeat(3, 1fr)",
        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "repeat(2, 1fr)",
        },
        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: "repeat(1, 1fr)",
        },
      }}
    >
      {data?.data.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            // Center the last row if it's a single card
            gridColumn:
              index === data.data.length - 1 && data.data.length % 3 === 1
                ? "1 / -1"
                : "auto",
            [theme.breakpoints.down("md")]: {
              gridColumn:
                index === data.data.length - 1 && data.data.length % 2 === 1
                  ? "1 / -1"
                  : "auto",
            },
          }}
        >
          <Box
            sx={{
              borderRadius: "100%",
              width: "30px",
              height: "30px",
              padding: "1rem",
              background: "#000",
            }}
          >
            <img
              src={`${apiUrl1}${item?.attributes?.Image?.data?.attributes?.url}`}
              alt={item?.attributes?.Title}
              style={{
                width: "30px",
                height: "30px",
              }}
            />
          </Box>
          <Typography
            variant="h6"
            sx={{
              marginY: "10px",
              maxWidth: "300px",
              textAlign: "center",
            }}
          >
            {item.attributes.Title}
          </Typography>
          <Box sx={{ textAlign: "center", maxWidth: "300px", opacity: "0.4" }}>
            <div
              className="benefits__para"
              dangerouslySetInnerHTML={{
                __html: item.attributes.Descriptions,
              }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default BenifitCard;
