import React from "react";
import CustomContainer from "../CustomContainer";
import { Box, Container, Typography } from "@mui/material";
import Product from "./Product";

const YourProduct = () => {
  return (
    <CustomContainer>
      <Container
        sx={{
          maxWidth: "1250px !important",
        }}
      >
        <Box sx={{ marginY: "5rem" }}>
          <Typography
            align="center"
            sx={{
              fontSize: "42px",
              fontWeight: "700",
              color: "var(--text-primary)",
              marginX: "auto",
              maxWidth: "900px",
              lineHeight: "1.3",
            }}
          >
            Take Control of Your Content Journey with DITA Conversion
          </Typography>
          <Typography
            align="center"
            sx={{
              fontSize: "20px",
              fontWeight: "400",
              color: "var(--text-gray)",
              paddingY: "1rem",
              maxWidth: "800px",
              margin: "0 auto",
            }}
          >
            With DITA, your content becomes more than just documentation—it
            transforms into an actionable asset for more intellient delivery,
            streamlined workflows, and proactive support.
          </Typography>
          <Product />
        </Box>
      </Container>
    </CustomContainer>
  );
};

export default YourProduct;
