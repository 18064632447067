import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React from "react";

const stepList = [
  {
    title: "Assessment & Strategy Development",
    desc: "Content inventory analysis and migration strategy planning. Identify gaps, redundancies, and opportunities for content optimization.",
    step: "1",
  },

  {
    title: "Content Mapping & Transformation Planning",
    desc: "Define DITA structure and transform planning as per the content models, templates, and metadata standards for the DITA structure.",
    step: "2",
  },
  {
    title: "Content Transformation",
    desc: "Converting content to DITA-XML and make any necessary content modifications, such as restructuring or tagging.",
    step: "3",
  },
  {
    title: "Leverage Automation Tools",
    desc: "Leverage automation tools and manual quality check for batch processing to validate structural consistency, semantic accuracy and usability.",
    step: "4",
  },
  {
    title: "Testing, Publishing & Deployment",
    desc: "Structured DITA-XML content deployment into compliant DITA CCMS or content delivery platform for efficient content management and distribution.",
    step: "5",
  },
];

const StepCard = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginTop: "2rem",
        display: "grid",
        gridGap: "2rem",
        gridTemplateColumns: "repeat(5, 1fr)",
        justifyItems: "center", // Center cards within their grid cell

        [theme.breakpoints.down("lg")]: {
          gridTemplateColumns: "repeat(3, 1fr)",
        },

        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "repeat(2, 1fr)",
        },
        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: "repeat(1, 1fr)",
        },
      }}
    >
      {stepList.map((item, index) => (
        <Box
          key={index}
          sx={{
            cursor: "pointer",
            borderRadius: "0.5rem",
            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
            transition: "color 0.3s ease-in",
            padding: "2rem",
            background: "white",
            "&:hover": {
              background: "var(--gradient-bg)",
              color: "#fff",
            },
          }}
        >
          <Box>
            <Typography
              sx={{
                opacity: "0.4",
                fontSize: "15px",
              }}
            >
              Step {item.step}
            </Typography>
            <Typography
              sx={{ fontSize: "16px", marginY: "1rem", fontWeight: "500" }}
            >
              {item.title}
            </Typography>
            <Typography sx={{ fontSize: "16px" }}>{item.desc}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default StepCard;
