import React from "react";
import Card1 from "./Card1";

const Section4 = ({ cardItems }) => {
  const apiUrl1 = process.env.REACT_APP_API_URL;

  return <Card1 cardItems={cardItems} apiUrl1={apiUrl1} />;
};

export default Section4;
