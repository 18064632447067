import { Box } from "@mui/material";
import React from "react";
import productSS from "../../assets/new/Screenshot_1.gif";

const Product = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          marginTop: "1.5rem",
          marginX: "auto",
          borderRadius: "0.5rem",
        }}
      >
        <img
          src={productSS}
          alt=""
          style={{
            border: "2px solid var(--border)",
            borderRadius: "0.5rem",
          }}
        />
      </Box>
    </>
  );
};

export default Product;
