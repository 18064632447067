import React from "react";
import Banner from "../components/Banner";
import { Box } from "@mui/material";
import CustomContainer from "../components/CustomContainer";
import Timeline from "../components/Timeline/Timeline";
import Setup from "../components/Setup/Setup";
import BenefitsNew from "../components/BenefitsNew/BenefitsNew";
import YourProduct from "../components/Product/YourProduct";
import { Link } from "react-router-dom";

import RequestDemo from "../components/RequestDemo";

function Main() {
  return (
    <Box>
      <Box sx={{ background: "var(--gradient-bg)" }}>
        <CustomContainer>
          <Box className="banner__main">
            <Banner />
          </Box>
        </CustomContainer>
      </Box>
      <YourProduct />
      <Timeline />
      <Box
        sx={{
          background: "linear-gradient(#fff 0%, #f6f6f9 100%)",
          paddingBottom: "2rem",
        }}
      >
        <Setup />
      </Box>
      <Box
        sx={{
          paddingY: "4rem",
        }}
      >
        <BenefitsNew />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", marginY: "2rem" }}>
        <Link
          to={"/request-demo"}
          className="requestButton"
          style={{ zIndex: "1" }}
        >
          <RequestDemo />
        </Link>
      </Box>
    </Box>
  );
}

export default Main;
