import React from "react";
import { Box } from "@mui/material";

function RequestDemo({ fsize = "16px", bgColor = "var(--secondary)" }) {
  return (
    <Box
      component={"span"}
      sx={{
        color: "var(--white)",
        display: "inline-block",
        borderRadius: "100px",
        maxHeight: "38px",
        background: bgColor,
        padding: "10px 1rem",
        textTransform: "uppercase",
        fontWeight: "400",
        fontSize: fsize,

        "&:hover": {
          backgroundColor: "var(--button)",
        },
      }}
    >
      Request a demo
    </Box>
  );
}

export default RequestDemo;
