import React from "react";
import { Box, Container } from "@mui/material";

import Section1 from "./Banner/Section1";
import Section2 from "./Banner/Section2";
import Section3 from "./Banner/Section3";
import Section4 from "./Banner/Section4";
import Section5 from "./Banner/Section5";
import FetchData from "./Fetch";

function Banner() {
  const apiUrl =
    process.env.REACT_APP_API_URL + "/api/product-pages?populate=*";
  const { data } = FetchData({ url: apiUrl });

  console.log();

  return (
    <Container>
      <Box component={"div"}>
        <Section1 />
        <Box sx={{ marginTop: "5rem" }}>
          <Section2 cardItems={data?.data[0]} />
        </Box>
        <Section3 cardItems={data?.data[1]} />
        <Section4 cardItems={data?.data[2]} />
        <Section5 cardItems={data?.data[3]} />
      </Box>
    </Container>
  );
}

export default Banner;
