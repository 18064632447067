import React from "react";

import Card2 from "./Card2";

const Section3 = ({ cardItems }) => {
  const apiUrl1 = process.env.REACT_APP_API_URL;

  return <Card2 cardItems={cardItems} apiUrl1={apiUrl1} />;
};

export default Section3;
