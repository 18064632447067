import React from "react";
import OurSolution from "../components/SolutionPage/OurSolution";
import BannerSolution from "../components/SolutionPage/BannerSolution";
import CustomContainer from "../components/CustomContainer";
import { Container } from "@mui/material";

function SolutionPage() {
  return (
    <>
      <CustomContainer>
        <Container>
          <BannerSolution />
          <OurSolution />
        </Container>
      </CustomContainer>
    </>
  );
}

export default SolutionPage;
