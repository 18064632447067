import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React from "react";

const timelineList = [
  {
    title: "Week 1-2: Project Kickoff",
    desc: `Define project scope, goals, and success criteria alignment with stakeholders.`,
  },
  {
    title: "Week 3-4: Content Mapping",
    desc: `Content structuring, mapping, taxonomy building and planning as per DITA best practices.`,
  },
  {
    title: "Week 5-10: Transformation & Migration",
    desc: `Conversion and migration process acceleration with content refinement and tagging.`,
  },
  {
    title: "Week 11-15: Review, Testing, and Implementation",
    desc: `Comprehensive content reviews, testing, implementation, and Go-Live.`,
  },
];

const TimelineContent = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginTop: "3rem",
        display: "flex",
        borderTop: "2px solid var(--border)",

        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
          borderTop: "0",
          borderLeft: "2px solid var(--border)",
          paddingLeft: "1rem",
        },
      }}
    >
      {timelineList.map((item, index) => (
        <Box
          key={index}
          sx={{
            marginTop: "1rem",
            position: "relative",
            marginRight: "1rem",

            [theme.breakpoints.down("md")]: {
              marginTop: "0",
              paddingLeft: "1.5rem",
              marginBottom: "2rem",
              marginRight: "0",
            },

            "&::before": {
              position: "absolute",
              content: '""',
              height: "8px",
              width: "8px",
              backgroundColor: "var(--secondary)",
              top: "-21px",
              left: 0,
              borderRadius: "100%",
              [theme.breakpoints.down("md")]: {
                top: "10px",
                left: "-21px",
              },
            },
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            sx={{ fontSize: "1.30rem" }}
          >
            {item.title}
          </Typography>
          <Typography>{item.desc}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default TimelineContent;
