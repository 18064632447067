import React from "react";
import CustomContainer from "../CustomContainer";
import { Box, Container, Typography } from "@mui/material";
import StepCard from "./StepCard";

const Setup = () => {
  return (
    <CustomContainer>
      <Container
      // sx={{
      //   maxWidth: "1250px !important",
      // }}
      >
        <Box sx={{ marginY: "5rem" }}>
          <Typography
            align="center"
            sx={{
              fontSize: "42px",
              fontWeight: "700",
              color: "var(--text-primary)",
            }}
          >
            5 Step Conversion Approach
          </Typography>
          <Typography
            align="center"
            sx={{
              fontSize: "20px",
              fontWeight: "400",
              color: "var(--text-gray)",
              paddingY: "1rem",
            }}
          >
            Having your unstructured content converted to DITA, brings you the
            following business benefits.
          </Typography>
          <StepCard />
        </Box>
      </Container>
    </CustomContainer>
  );
};

export default Setup;
