import { Box, Container, Typography } from "@mui/material";
import CustomContainer from "../CustomContainer";
import BenifitCard from "./BenifitCard";

const BenefitsNew = () => {
  return (
    <CustomContainer>
      <Container
        sx={{
          maxWidth: "1250px !important",
        }}
      >
        <Box sx={{ marginBottom: "4rem" }}>
          <Typography
            align="center"
            sx={{
              fontSize: "42px",
              fontWeight: "700",
              color: "var(--text-primary)",
            }}
          >
            Benefits
          </Typography>
          <Typography
            align="center"
            sx={{
              fontSize: "20px",
              fontWeight: "400",
              color: "var(--text-gray)",
              paddingY: "1rem",
            }}
          >
            Having your unstructured content converted to DITA, brings you the
            following business benefits.
          </Typography>
        </Box>
        <BenifitCard />
      </Container>
    </CustomContainer>
  );
};

export default BenefitsNew;
