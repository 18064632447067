import { useTheme } from "@emotion/react";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const Card2 = ({ cardItems, apiUrl1 }) => {
  const theme = useTheme();
  return (
    <Grid
      container
      spacing={2}
      sx={{
        marginBottom: "5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column-reverse",
        },
      }}
    >
      <Grid
        item
        md={6}
        style={{
          overflow: "hidden",
          position: "relative",
          height: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            margin: "0 auto",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <img
            src={`${apiUrl1}${cardItems?.attributes?.Image?.data[0]?.attributes?.url}`}
            alt="ssd"
            style={{
              borderRadius: "0.5rem",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              flex: "0 0 auto",
            }}
          />
        </div>
      </Grid>
      <Grid
        item
        md={6}
        style={{
          overflow: "hidden",
          position: "relative",
          height: "100%",
          width: "100%",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: "700",
              color: "var(--white)",
              fontSize: {
                xs: "32px",
                sm: "32px",
                md: "32px",
                lg: "32px",
              },

              lineHeight: {
                xs: "40px",
                sm: "55px",
                md: "55px",
                lg: "60px",
              },
              // [theme.breakpoints.down("md")]: {
              //   textAlign: "center",
              // },
            }}
          >
            {cardItems?.attributes.Title}
          </Typography>
          <Box
            as="div"
            sx={{
              marginTop: "10px",
              marginBottom: "2rem",
              lineHeight: "28.13px",
              fontSize: "16px",
              color: "var(--white)",
              fontWeight: "400",
              // [theme.breakpoints.down("md")]: {
              //   textAlign: "center",
              // },
            }}
          >
            <div
              className="card__desc"
              dangerouslySetInnerHTML={{
                __html: cardItems?.attributes?.Descriptions,
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Card2;
